import { Directory } from '..';
import React from 'react';

export class Folder extends Directory {

	// Non-recursive version of Directory

	componentDidMount = () => {
		const { myPath } = this.state;
		const {
			name,
			path,
		} = this.props;
		// console.log("Folder componentDidMount path", path); // eslint-disable-line
		if (path && !myPath) {
			this.setState({
				myPath: `${path.trim()}${name}`
			});
		}
	};

	handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		// this.toggleExpand(e);
		this.select(e);
		const {
			handleSelect,
		} = this.props;
		const {
			// expanded,
			myPath
		} = this.state;

		const folder = e.target.closest('.folder');
		let isFolder = false;
		if (folder) {
			// console.log(folder.classList);
			// Double check
			if (folder.classList && folder.classList.contains('folder'))isFolder = true;
		}


		if (this.singleClick) {
			// Double click!
			// console.log("DOUBLE CLICK!"); // eslint-disable-line
			handleSelect(`${myPath}`, isFolder, true, true);
		} else {
			handleSelect(`${myPath}`, isFolder, false, false);
			this.singleClick = true;
			this.doubleClickTimer = setTimeout(() => this.singleClick = false, 500);
		}
	};

	render() {

		const {
			name,
			selectedPath,
			view = 'icons',
		} = this.props;
		let {className } = this.props;
		const {
			myPath,
		} = this.state;
		if (name && myPath) {
			// console.log("myPath", myPath, "name", name);
			if (className && className.includes('file')) {
				const [extension] = myPath.split('.');
				if (extension === 'sdproj' || extension === 'add')className += ' stardrop';
			}
			return (
				<div
					className={`
					${className} 
					${selectedPath === myPath ? 'selected' : ''}
					${view}
					`}
					onClick={(e) => this.handleClick(e)}
					title={name}
				>
					<p
						className="folder-title"
					>{name}
						{/* <span className={`debug`} >nbsp;<i>{myPath}</i>&nbsp;<b>{selectedPath === myPath ? 't' : 'f'},{selectedPath},{myPath}</b></span> */}
					</p>
				</div>
			);
		}
	}
}