import { Folder } from '..';
import React from 'react';

export class Data extends Folder {

	componentDidMount = () => {
		const { myPath } = this.state;
		const {
			name,
			path,
		} = this.props;
		// console.log("Data componentDidMount path", path,"name",name); // eslint-disable-line
		if (path && !myPath) {
			this.setState({
				myPath: `${path.trim()}/${name}`.replace('//', '/')
			});
		}
	};

	componentDidUpdate = (oldProps) => {
		// const {
		// 	myPath,
		// } = this.state;
		const {
			path: oldPath
		} = oldProps;
		const {
			name,
			path,
		} = this.props;

		// console.log("Data componentDidUpdate myPath", myPath, "name", name);
		if (path !== oldPath) {
			this.setState({
				myPath: `${path.trim()}/${name}`.replace('//', '/')
			});
		}
	};

	render() {

		const {
			data,
			selectedPath,
		} = this.props;
		const {
			myPath,
		} = this.state;
		// console.log("myPath", myPath);
		if (myPath) {
			return (
				<div className={`data ${selectedPath === myPath ? 'selected' : ''}`}>
					<p>{data}
						{/* <span className={`debug`} >&nbsp;<i>{myPath}</i>&nbsp;<b>{selectedPath === myPath ? 't' : 'f'},{selectedPath},{myPath}</b></span> */}
					</p>
				</div>
			);
		}
	}
}