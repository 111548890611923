import './Login.scss';
import {
	CookieBanner,
	Footer,
	PasswordFieldWithEye,
} from '../../components';
import {
	getCookie,
	setCookie,
} from '../../utility';
import optibriumDark from '../../images/optibrium_logo_dark_text.svg';
import React from 'react';
export class Login extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			collapse: false,
			email: '',
			eulaAccepted: false,
			password: '',
			valid: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.validate = this.validate.bind(this);
		this.validateAndHandleForgotPassword = this.validateAndHandleForgotPassword.bind(this);

		this.emailRef = React.createRef();
	}

	componentDidMount = () => {
		// Get eula acceptance from session cookie
		const eulaAccepted = getCookie('eulaAccepted');
		if (eulaAccepted === 'true') {
			this.setState({
				eulaAccepted: true,
			});
		}
		// Didn't work
		// setTimeout(() => {
		// 	const email = document.getElementById('email');
		// 	const password = document.getElementById('password');
		// 	if (email.value !== '' && password.value !== '') {
		// 		this.setState({
		// 			email: email.value,
		// 			password: password.value,
		// 		}, this.validate);
		// 	}
		// }, 5000);
	};

	fetchSSOConfig = (SSOEndpoint, emailDomain, appName) => {
		return fetch(`${SSOEndpoint}?domain=${emailDomain.toLocaleLowerCase()}&product=${appName}`)
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.catch(error => {
				// console.error('Error fetching SSO config:', error);
				return null;
			});
	};

	handleChange = (field, value) => {
		const { SSOEndpoint, appName } = this.props;
		switch (field) {
			case 'eulaAccepted': {

				this.setState({ [field]: value }, this.validate);
				const consent = JSON.parse(localStorage.getItem('consentMode'));
				if (consent) {
					const { personalisation } = consent;
					if (personalisation === 'granted') setCookie('eulaAccepted', value, 30);
				}
				break;
			}
			case 'email': {
				this.setState({
					SSOEnabled: false,
					SSOName: '',
				});

				if (value.includes('@')) {
					const emailDomain = value.split('@').pop();
					if (emailDomain.includes('.') && emailDomain.split('.').pop().length > 1) {
						this.fetchSSOConfig(SSOEndpoint, emailDomain, appName).then(SSOConfigs => {
							if (SSOConfigs) {
								const { SSOName } = SSOConfigs;
								this.setState({
									SSOEnabled: true,
									SSOName: SSOName,
								});
								this.setState({ [field]: value }, this.validate);
							}
						});
					}
				}
				this.setState({ [field]: value }, this.validate);
				break;
			}
			default: {
				this.setState({ [field]: value }, this.validate);
				break;
			}
		}
	};


	render() {
		const {
			adminPanel,
			appName,
			animated,
			handleLogin,
			loginError = ''
		} = this.props;

		const {
			email,
			eulaAccepted,
			password,
			valid,
			SSOEnabled,
			SSOName
		} = this.state;

		// console.log("eulaAccepted", eulaAccepted);
		return (
			<div id={`login`} className={`${adminPanel ? 'file-manager' : ''}`}>
				<a
					id='optibrium'
					href='https://optibrium.com'
				>
					<img
						alt='Optibrium'
						src={optibriumDark}
					/>
				</a>
				<CookieBanner />
				<div id='container' className={`${animated ? "animated" : "static"}}`}>
					<form
						autoComplete='off'
						id="signinForm"
						onSubmit={handleLogin}
					>
						<div className='narrow'>
							{appName === 'StarDrop' ?
								<div
									alt='StarDrop'
									id='welcomeStardrop'
									title='StarDrop'
								>
									{!adminPanel ?
										<span className='welcome-to'>Welcome to</span>
										:
										null
									}
									{
										adminPanel ?
											<span className='admin-panel'>Admin panel</span>
											:
											null
									}
								</div>
								:
								<div
									alt='Semeta'
									id='welcomeSemeta'
									title='Semeta'
								>
									{!adminPanel ?
										<span className='welcome-to'>Welcome to</span>
										:
										null
									}
								</div>
							}
							<input
								autoComplete='new-email' // Pretty much impossible: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
								className={`text-field ${loginError && loginError !== '' ? 'error' : ''}`}
								id="email"
								onChange={(e) => this.handleChange('email', e.target.value)}
								placeholder="Email"
								ref={this.emailRef}
								required
								type="email"
								value={email}
							/>
							<div
								className={`error ${loginError && loginError !== '' ? 'show' : ''}`}
								id='error'
							>{loginError}</div>
							{!SSOEnabled ?
								<div>
									<div
										className={`error ${loginError && loginError !== '' ? '' : 'show'}`}
										id='errorPlaceholder'>&nbsp;</div>
									<PasswordFieldWithEye
										id={"password"}
										handleChange={this.handleChange}
										loginError={loginError}
										placeholder={"Password"}
										value={password}
									/>
									{/* <a className={`forgot-password`} href={`https://optibrium.com/stardrop-help/`}>Forgot password?</a> */}
									<span type="submit" className={`forgot-password`} title='Forgot password' onClick={(e) => this.validateAndHandleForgotPassword(e)}>Forgot password?</span>
									<button
										className={`${!valid ? 'disabled' : ''}`}
										disabled={!valid}
										id="submit"
										type="submit"
									>Log in</button>
								</div>
								:
								<div>
									<button
										className={`${!valid ? 'disabled' : ''}`}
										disabled={!valid}
										id="submit"
										type="submit"
									>Log in with {SSOName}</button>
								</div>
							}
						</div>
						<div id='eulaText'>
							<p>To access the {appName}&trade; software, you must agree to the terms of the {appName}&trade; End User Licence Agreement below.</p>
							<label forhtml='eula'>
								<input
									type='checkbox'
									id='eulaAccepted'
									checked={eulaAccepted}
									onChange={(e) => this.handleChange(`eulaAccepted`, !eulaAccepted)}
								/>
								&nbsp;&nbsp;&nbsp;&nbsp;I have read and agree to the terms of this {appName}&trade; licence.</label>
							<div id='scrollIfNeedTo'>
								<h2>{appName}&trade; End User Licence Agreement</h2>
								<p className={`eula`}>This&nbsp;
									{
										appName === 'StarDrop' ?
											<a className='licence-link licence-link-one' href='https://optibrium.com/stardrop-end-user-licence-agreement/' target='_blank' rel="noreferrer"> end user licence agreement</a>
											:
											<a className='licence-link licence-link-one' href='https://optibrium.com/semeta-end-user-licence-agreement/' target='_blank' rel="noreferrer"> end user licence agreement</a>
									}
									&nbsp;(<b>Agreement</b>) is a legal agreement between the entity you are acting on
									behalf of (being your employer or client) (the <b>Customer</b>) and Optibrium Limited (<b>Optibrium</b>)
									for {appName}&trade;
									computer software, the data supplied with the software, and the associated media (<b>Software</b>),
									together with any documentation to facilitate your access and use of the Software (<b>Documents</b>).</p>
								<p>We, Optibrium, license the use of the Software and Documents to the Customer and you (as a Permitted User)
									on the terms and conditions of the Agreement. We do not sell the Software or Documents to you or the
									Customer. We remain the owners of the Software and Documents at all times.</p>

								<p><b>BY ACCESSING THE STARDROP SOFTWARE, YOU CONFIRM THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO
									BE BOUND BY THE TERMS OF THE END USER LICENCE AGREEMENT. IF YOU DO NOT AGREE TO THESE TERMS,
									DO NOT USE THE SOFTWARE AND UNINSTALL IT FROM YOUR DEVICE.</b></p>

							</div>
						</div>
					</form>
					<Footer
						adminPanel={adminPanel}
						appName={appName}
					/>
				</div>
			</div>

		);
	};

	validate = () => {
		const {
			email,
			eulaAccepted,
			password,
			SSOEnabled,
		} = this.state;
		// console.log(`valid email: ${email} password: ${password} eulaAccepted: ${eulaAccepted}`);
		const valid = email && email !== '' && ((password && password !== '') || SSOEnabled) && eulaAccepted;
		this.setState({
			valid: valid
		});
		return valid;
	};

	validateAndHandleForgotPassword = (e) => {
		const {
			dialog,
			handleForgotPassword,
		} = this.props;
		// console.log("validateAndHandleForgotPassword", e, this.emailRef);
		const email = this.emailRef.current.value;

		if (!email) {
			dialog("Please enter your registered email address");
			return;
		}
		handleForgotPassword(email);

	};
}

