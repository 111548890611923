import './FileUpload.scss';
import {
	// b64EncodeUnicode,
	handleIncorrectAWSResponse,
	handleResponse,
} from '../../../utility';
import React from 'react';

export class FileUpload extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			dragActive: false,
		};

		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
		this.handleDrag = this.handleDrag.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.setDragActive = this.setDragActive.bind(this);

		this.inputRef = React.createRef();
	}

	handleChange = (e) => {
		// console.log("handleChange");
		// triggers when file is selected with click
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			// at least one file has been selected so do something
			this.handleFiles(e.target.files);
		}
	};

	handleDrag = (e) => {
		// console.log("handleDrag");
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			this.setDragActive(true);
		} else if (e.type === "dragleave") {
			this.setDragActive(false);
		}
	};

	handleDrop = (e) => {
		// triggers when file is dropped
		// console.log("handleDrop");
		e.preventDefault();
		e.stopPropagation();
		this.setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			// at least one file has been dropped so do something
			this.handleFiles(e.dataTransfer.files);
		}
	};

	handleButtonClick = (e) => {
		// console.log("handleButtonClick");
		e.preventDefault();
		e.stopPropagation();
		this.inputRef.current.click();
	};

	handleFiles = (files) => {
		const {
			APIEndpoint,
			handleGetFiles,
			jwtToken,
			logError,
			selectedPath,
		} = this.props;

		// console.log("handleFiles APIEndpoint", APIEndpoint);

		// console.log(files);
		for (let i = 0; i < files.length; i++){
			const file = files.item(i);
			// console.log(file);

			const headers = new Headers();
			headers.append("Authorization", jwtToken);

			const RequestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};
			const {
				name,
				type,
			} = file;
			// console.log(`put-url ${selectedPath}/${name}`);
			let targetPath = selectedPath.slice(1);
			if (targetPath.length > 0)targetPath += '/';
			// console.log(`put-url targetPath = ${targetPath}${name}`);
			fetch(`${APIEndpoint}/put-url?path=${targetPath}${name}`, RequestOptions)
				.then(handleResponse)
				.then(res => {
					// console.log("files res", res); // eslint-disable-line
					const { Message } = res;
					const headers = new Headers();
					headers.append("Content-Type", type);
					const fileData = new Blob([file], { type: file.type });

					const RequestOptions = {
						body: fileData,
						headers: headers,
						method: 'PUT',
						redirect: 'follow',
					};
					fetch(`${Message}`, RequestOptions)
						.then(handleIncorrectAWSResponse)
						.then(res => {
							// console.log("PUT res", res);
							handleGetFiles();
							return Promise.resolve();
						})
						.catch(error => {
							logError('PUTting file', error);
							return Promise.resolve();
						});
					return Promise.resolve();
				})
				.catch(error => {
					logError("Putting URL", error);
					return Promise.resolve();
				});
		};

	};

	render() {
		const { dragActive } = this.state;
		return (
			<form id="formFileUpload" onDragEnter={this.handleDrag} onSubmit={(e) => e.preventDefault()}>
				<input
					id="inputFileUpload"
					multiple={true}
					onChange={this.handleChange}
					ref={this.inputRef}
					type="file"
				/>
				<label
					className={dragActive ? "drag-active" : "" }
					htmlFor="inputFileUpload"
					id="labelFileUpload"
				>
					<div>
						<p>Drag and drop your file here or</p>
						<button className="upload-button" onClick={this.handleButtonClick}>Upload a file</button>
					</div>
				</label>
				{dragActive && <div
					id="dragFileElement"
					onDragEnter={this.handleDrag}
					onDragLeave={this.handleDrag}
					onDragOver={this.handleDrag}
					onDrop={this.handleDrop}
				></div>}
			</form>
		);
	}

	setDragActive = (active) => {
		// console.log("setDragActive");
		this.setState({
			dragActive: active,
		});
	};
}