// import './Directory.scss';
import React from 'react';

export class Directory extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
			myPath: undefined,
		};

		this.handleClick = this.handleClick.bind(this);
		this.select = this.select.bind(this);

	}

	componentDidMount = () => {
		const {
			myPath,
		} = this.state;
		const {
			files,
			path,
		} = this.props;
		// console.log("Directory componentDidMount path", path,path.length," myPath",myPath); // eslint-disable-line
		if (!myPath) {
			// console.log("SET");
			this.setState({
				myPath: `${path}/${files.name}`.replace('//', '/')
			});

		}
	};

	handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();

		this.select(e);
		const {
			handleSelect,
		} = this.props;

		const {
			myPath
		} = this.state;

		if (this.singleClick) {
			// Double click!
			// console.log("DOUBLE CLICK!"); // eslint-disable-line
			handleSelect(myPath, true, true, true);
		} else {
			handleSelect(myPath, true, false, true);
			this.singleClick = true;
			this.doubleClickTimer = setTimeout(() => this.singleClick = false, 500);
		}
	};

	render() {

		const {
			files,
			handleSelect,
			selection,
		} = this.props;

		const {
			expand = false,
			myPath,
		} = this.state;

		const {
			path: selectedPath,
			toggleExpand = false,
		} = selection;

		if (files && myPath) {
			let expanded = false;
			// console.log(`1 Directory render myPath:${myPath} selectedPath:${selectedPath} toggleExpand:${toggleExpand} expanded:${expanded} expand:${expand}`);
			if ((myPath === selectedPath) && toggleExpand) expanded = !expand;
			// console.log(`2 Directory render myPath:${myPath} selectedPath:${selectedPath} toggleExpand:${toggleExpand} expanded:${expanded} expand:${expand}`);
			if ((myPath.length < selectedPath.length) && selectedPath.includes(myPath)) expanded = true;
			// console.log(`3 Directory render myPath:${myPath} selectedPath:${selectedPath} toggleExpand:${toggleExpand} expanded:${expanded} expand:${expand}`);

			return (
				<div
					className={`
					folder 
					${expanded ? 'expanded' : ''} 
					${selectedPath === myPath ? 'selected' : ''}
					${myPath === '/' || myPath === '/StarDrop' || myPath === '/StarDrop/Resources' ? 'hidden' : ''}
					`}
					onClick={(e) => this.handleClick(e)}
				>
					<p
						className={`folder-title prevent-select`}
					>{files.name}
						{/* <span className={`debug`} >&nbsp;<i>e {expanded ? 't' : 'f'}</i>
							<i> {myPath} </i>
							<i> {myPath.length}:{selectedPath.length} </i>
							<i>sp {selectedPath.includes(myPath) ? 't' : 'f'}</i></span> */}
					</p>
					{
						expanded && files.items.map((item) => <Directory
							key={`directory${myPath}${item.name}`}
							files={item}
							handleSelect={handleSelect}
							path={myPath}
							selection={selection}
						/>)
					}
				</div>
			);
		}
	}

	select = (e) => {
		this.setState({
			selected: true,
		});
	};
}