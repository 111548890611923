import './FileManager.scss';
import {
	Data,
	Directory,
	FileUpload,
	Folder,
} from '.';
import {
	handleIncorrectAWSResponse,
	handleResponse,
} from '../../utility';
import {
	Footer,
} from '..';
import React from 'react';
import Split from 'react-split';
import starDropLight from '../../images/stardrop_light.svg';


export class FileManager extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			collapse: false,
			selection: {
				path: '/StarDrop/Resources/',
				toggleExpand: false,
			}
		};

		this.areYouSure = this.areYouSure.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleGetFiles = this.handleGetFiles.bind(this);
		this.handleGetFolders = this.handleGetFolders.bind(this);
		this.handleNewFolder = this.handleNewFolder.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleUploadFolder = this.handleUploadFolder.bind(this);

		this.loginExpireMessage = "Your login has expired, please log in again";

	}

	areYouSure = (e, affirmativeAction) => {
		const { dialog } = this.props;
		e.preventDefault();
		e.stopPropagation();
		dialog('Are you sure?', undefined, 'yesno', affirmativeAction, () => { });
	};

	componentDidMount = () => {
		const { handleShowSpinner } = this.props;

		const {
			files,
			hierarchy,
		} = this.state;

		if (!hierarchy) this.handleGetFolders();

		if (!files) this.handleGetFiles();

		if (hierarchy && files)handleShowSpinner(false);

	};

	handleChange = (field, value) => {
		switch (field) {
			default: {
				this.setState({ [field]: value });
				break;
			}
		}
	};

	handleDelete = (e) => {
		const {
			selection,
		} = this.state;
		const {
			expand,
			isFolder,
			path: selectedPath,
		} = selection;
		// console.log("handleDelete selectedPath", selectedPath);
		// e.preventDefault();
		// e.stopPropagation();
		const {
			APIEndpoint,
			dialog,
			handleLogout,
			jwtToken,
			logError,
		} = this.props;
		const headers = new Headers();
		headers.append("Authorization", jwtToken);

		const RequestOptions = {
			headers: headers,
			method: 'DELETE',
			redirect: 'follow',
		};

		let searchPath = selectedPath;
		if (searchPath[0] === '/') searchPath = searchPath.substring(1);
		if (isFolder) searchPath += '/';

		fetch(`${APIEndpoint}/content?path=${searchPath}`, RequestOptions)
			.then(handleResponse)
			.then(res => {
				// console.log("files res", res);
				// console.log("handleDelete isFolder", isFolder);
				const pathSliced = selectedPath.split('/');
				const parentPath = pathSliced.slice(0, pathSliced.length - 1);

				// Note
				// '/' returns path '/'
				// '/py3' returns '/py3/' no files or folders so bad request?
				// '/py3/' returns '/py3/' no files or folders so bad request?
				// 'py3/' returns 'py3/' with files & dirs
				// 'py3/Utils' returns 'py3/Utils/' with files & dirs

				// Append a '/'?
				let parentPathString = parentPath.join('/');
				if (parentPathString.slice(-1) !== '/') parentPathString = `${parentPathString}/`;
				// Remove root slash?
				// if (parentPathString[0] === '/')parentPathString = parentPathString.slice(1);
				if (isFolder) {
					this.setState({
						selection: {
							expand: expand,
							isFolder: true,
							path: parentPathString,
						}
					}, () => {
						this.handleGetFolders();
						this.handleGetFiles();
					});
				}else {
					this.handleGetFiles();
				}
				// console.log("directory", directory);
				// if (directory === '') directory = '/';
				// this.handleSelect(directory, false, true);
				return Promise.resolve();
			})
			.catch(error => {
				if (error.status === 401) {
					dialog(this.loginExpireMessage, 'theme', 'clear', handleLogout, handleLogout);
					return Promise.resolve();
				}
				logError("Deleting file/folder", error);
				return Promise.resolve();
			});

	};

	handleGetFiles = () => {
		const {
			APIEndpoint,
			dialog,
			handleLogout,
			handleShowSpinner,
			jwtToken,
			logError,
		} = this.props;
		const {
			selection
		} = this.state;
		const {
			path: selectedPath,
		} = selection;
		const headers = new Headers();
		headers.append("Authorization", jwtToken);

		const RequestOptions = {
			headers: headers,
			method: 'GET',
			redirect: 'follow',
		};

		let searchPath = selectedPath;
		if (searchPath[0] === '/')searchPath = searchPath.slice(1);

		// Note
		// '/' returns path '/'
		// '/py3' returns '/py3/' no files or folders so bad request?
		// '/py3/' returns '/py3/' no files or folders so bad request?
		// 'py3/' returns 'py3/' with files & dirs
		// 'py3/Utils' returns 'py3/Utils/' with files & dirs

		fetch(`${APIEndpoint}/content?path=${searchPath}`, RequestOptions)
			.then(handleResponse)
			.then(res => {
				let { path } = res;
				if (path[0] !== '/') path = `/${path}`;
				delete res.path;

				// console.log("files res", res);
				this.setState({
					path: path,
					...res,
				});
				handleShowSpinner(false);
				return Promise.resolve();
			})
			.catch(error => {
				if (error.status === 401) {
					dialog(this.loginExpireMessage, 'theme', 'clear', handleLogout, handleLogout);
					return Promise.resolve();
				}
				logError("Getting files", error);
				handleShowSpinner(false);
				return Promise.resolve();
			});

	};

	handleGetFolders = () => {
		const {
			APIEndpoint,
			dialog,
			handleLogout,
			handleShowSpinner,
			jwtToken,
			logError,
		} = this.props;

		const headers = new Headers();
		headers.append("Authorization", jwtToken);

		const RequestOptions = {
			headers: headers,
			method: 'GET',
			// mode: 'no-cors',
			redirect: 'follow',
		};

		fetch(`${APIEndpoint}/content/tree`, RequestOptions)
			.then(handleResponse)
			.then(res => {
				// console.log("tree res", res);
				this.setState({
					hierarchy: res,
				});
				handleShowSpinner(false);
				return Promise.resolve();
			})
			.catch(error => {
				if (error.status === 401) {
					dialog(this.loginExpireMessage, 'theme', 'clear', handleLogout, handleLogout);
					return Promise.resolve();
				}
				logError("Getting folder hierarchy", error);
				handleShowSpinner(false);
				return Promise.resolve();
			});
	};

	handleNewFolder = () => {
		const {
			dialog
		} = this.props;
		// console.log("handleNewFolder");
		const folderNameJSX = [];
		folderNameJSX.push(
			<>
				<label forhtml={`folderName`} >Folder name:&nbsp;<input
					autoFocus
					id={`folderName`}
					onChange={(e) => this.handleChange(`folderName`, e.target.value)}
					tabIndex={0}
				/></label>
			</>
		);
		dialog(folderNameJSX, 'theme', 'cancelok', this.handleUploadFolder);
	};

	handleUploadFolder = () => {
		const {
			APIEndpoint,
			jwtToken,
			logError,
		} = this.props;
		const {
			folderName,
			selection,
		} = this.state;

		const { path: selectedPath } = selection;

		// console.log("handleUploadFolder APIEndpoint", APIEndpoint);

		// console.log(folderName);

		const headers = new Headers();
		headers.append("Authorization", jwtToken);

		const RequestOptions = {
			headers: headers,
			method: 'GET',
			redirect: 'follow',
		};
		let targetPath = selectedPath.slice(1);
		if (targetPath.length > 0)targetPath += '/';
		// console.log(`put-url targetPath = ${targetPath}${folderName}/`);
		fetch(`${APIEndpoint}/put-url?path=${targetPath}${folderName}/`, RequestOptions)
			.then(handleResponse)
			.then(res => {
				// console.log("files res", res);
				const { Message } = res;
				const headers = new Headers();
				headers.append("Content-Type", "application/json");
				// headers.append("Content-Type", type);
				// const fileData = URL.createObjectURL(file);

				const RequestOptions = {
					headers: headers,
					method: 'PUT',
					redirect: 'follow',
				};
				fetch(`${Message}`, RequestOptions)
					.then(handleIncorrectAWSResponse)
					.then(res => {
						// console.log("PUT res", res);
						this.handleGetFiles();
						this.handleGetFolders();
						return Promise.resolve();
					})
					.catch(error => {
						logError('PUTting file', error);
						return Promise.resolve();
					});
				return Promise.resolve();
			})
			.catch(error => {
				logError("Putting URL", error);
				return Promise.resolve();
			});

	};

	handleSelect = (path, folder = false, toggleExpand, getFiles = false) => {
		// console.log(`handleSelect path: ${path} length: ${path.length} toggleExpand: ${toggleExpand?'t':'f'}`);
		this.setState({
			selection: {
				isFolder: folder,
				path: path,
				toggleExpand: toggleExpand,
			},
		}, () => { if (getFiles)this.handleGetFiles(); });
		// console.log(`handleSelect selection: `, selection);
	};

	getFileExtension = (fileName) => {
		return fileName.substring(fileName.lastIndexOf(".") + 1);
	};

	render() {
		const {
			app,
			APIEndpoint,
			handleLogout,
			jwtToken,
			logError,
		} = this.props;
		// console.log();

		const {
			// toggleExpand = false,
			hierarchy,
			files,
			folders,
			path,
			selection,
			view = 'detail',
		} = this.state;

		const {
			path: selectedPath,
			toggleExpand,
		} = selection;

		// console.log("App render toggleExpand", toggleExpand);

		if (files && hierarchy) {

			const filesJSX = [];
			const fileSizesJSX = [];
			const fileDatesJSX = [];
			const folderSizesJSX = [];
			const folderDatesJSX = [];
			const foldersJSX = [];
			// Column headers
			if (view === 'detail') {
				folderSizesJSX.push('Size');
				folderDatesJSX.push('Last Modified');
				foldersJSX.push('Name');
			}
			folders.forEach((folder, index) => {
				const name = folder;
				foldersJSX.push(
					<li key={`folderLi${path}${name}${index}`}>
						<Folder
							className='folder'
							handleSelect={this.handleSelect}
							key={`folder${path}${index}`}
							name={name}
							path={`${path}`}
							selectedPath={selectedPath}
							view={view}
						/>
					</li>
				);
				folderSizesJSX.push(
					<li key={`folderSizeli${index}`}>
						<div className={`data`} key={`folderSizeLiDiv${index}`}><p>&nbsp;</p></div>
					</li>
				);
				folderDatesJSX.push(
					<li key={`folderDateLi${index}`}>
						<div className={`data`} key={`folderDateLiDiv${index}`}><p>&nbsp;</p></div>
					</li>
				);
			});
			const starDropExtensions = ['sdproj', 'add'];
			files.forEach((file) => {
				const { name, lastModified, size } = file;
				const extension = this.getFileExtension(name).toLowerCase();
				filesJSX.push(
					<li key={`fileLi${path}${name}`}>
						<Folder
							className={`file ${starDropExtensions.includes(extension) ? 'stardrop' : ''}`}
							handleSelect={this.handleSelect}
							lastModified={lastModified}
							name={name}
							key={`folder${path}${name}`}
							path={`${path}`}
							selectedPath={selectedPath}
							size={size}
							view={view}
						/>
					</li>
				);
				fileSizesJSX.push(
					<li key={`fileSizeLi${path}${name}`}>
						<Data
							data={size}
							name={name}
							key={`folderSize${path}${name}`}
							path={`/${path}`}
							selectedPath={selectedPath}
						/>
					</li>
				);
				fileDatesJSX.push(
					<li key={`fileDateLi${path}${name}`}>
						<Data
							data={lastModified}
							name={name}
							path={`/${path}`}
							selectedPath={selectedPath}
						/>
					</li>
				);
			});

			return (
				<div id='adminPanel' className='file-manager'>
					<header>
						{/* <div id={`path`}>
							<span className={`debug`} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							{selectedPath.length > 0 ? selectedPath : '/'}
							<span className={`debug`} >&nbsp;&nbsp;&nbsp;DEBUG: selectedPath: {selectedPath} toggleExpand: {toggleExpand ? 't' : 'f'}</span>
						</div> */}
						<img alt='StarDrop&trade;' title='StarDrop&trade;' src={starDropLight} />
						<h1>Admin panel</h1>
						<button className={`logout`} onClick={handleLogout}>End session</button>
						<p>Upload files to the appropriate directories if you want to share them. Select a folder and scroll down to the drag and drop panel or select Upload a file.</p>
					</header>
					<div className={`to-be-scrollable`}>
						<Split
							cursor="col-resize"
							direction="horizontal"
							dragInterval={1}
							expandToMin={false}
							gutterAlign="center"
							gutterSize={6}
							id={`split`}
							sizes={[40, 60]}
							snapOffset={30}
							minSize={100}
						>
							<div id='hierarchy'>
								<Directory
									toggleExpand={toggleExpand}
									files={hierarchy}
									handleSelect={this.handleSelect}
									path={''}
									selection={selection}
								/>
							</div>
							<div className={`${view}`} id='files'>
								{/* <button
									alt='details'
									className={`details ${view === 'detail' ? 'selected' : ''}`}
									onClick={() => this.setState({ view: 'detail' })}
									title='details'
								>details</button>
								<button
									alt='icons'
									className={`icons ${view === 'icons' ? 'selected' : ''}`}
									onClick={() => this.setState({ view: 'icons' })}
									title='icons'
								>icons</button> */}
								<button
									alt='New folder'
									className={`new-folder`}
									onClick={this.handleNewFolder}
									title='New folder'
								>New folder</button>
								<button
									alt='Delete'
									className={`delete`}
									onClick={(e) => this.areYouSure(e, this.handleDelete)}
									title='Delete'
								>Delete</button>
								{view === 'icons' ?
									<>
										<ul>{foldersJSX}</ul>
										<ul>{filesJSX}</ul>
									</>
									:
									<Split
										cursor="col-resize"
										direction="horizontal"
										dragInterval={1}
										expandToMin={false}
										gutterAlign="center"
										gutterSize={5}
										id={`fileSplit`}
										sizes={[33, 33, 33]}
										snapOffset={30}
										minSize={100}
									>
										<div>
											<ul>{foldersJSX}</ul>
											<ul>{filesJSX}</ul>
										</div>
										<div>
											<ul>{folderDatesJSX}</ul>
											<ul>{fileDatesJSX}</ul>
										</div>
										<div>
											<ul>{folderSizesJSX}</ul>
											<ul>{fileSizesJSX}</ul>
										</div>
									</Split>
								}
								<FileUpload
									APIEndpoint={APIEndpoint}
									handleGetFiles={this.handleGetFiles}
									jwtToken={jwtToken}
									logError={logError}
									selectedPath={selectedPath}
								/>
							</div>
						</Split>
					</div>
					<Footer
						app={app} />
				</div>

			);
		}
	};
}