import './CookieBanner.scss';
import React from 'react';

export class CookieBanner extends React.Component {
	constructor(props) {
		super(props);

		this.gtag = this.gtag.bind(this);
		this.handleAccept = this.handleAccept.bind(this);
		this.handleDecline = this.handleDecline.bind(this);
		this.hideBanner = this.hideBanner.bind(this);
		this.setConsent = this.setConsent.bind(this);
	}

	componentDidMount = () => {
		// Reveal the cookie banner if there is no record of cookie consents recorded previously
		// Using display none/flex as setting state for banner visibility in here would result in a second render
		if (localStorage.getItem('consentMode') === null) {
			document.getElementById('CookieBanner').style.display = 'flex';
		}
	};

	gtag = (consent) => {
		// Taken from the sample code see: https://www.youtube.com/watch?v=MqAEbshMv84&t=116s
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push('consent', 'update', consent);
	};

	handleAccept = () => {
		// Taken from the sample code see: https://www.youtube.com/watch?v=MqAEbshMv84&t=116s
		// analytics and marketing are used by the Google Tag Manager code, preferences added
		// by me for EULA acceptance to be 'remembered'
		this.setConsent({
			analytics: true,
			marketing: true,
			preferences: true,
		});
		// Clarity cookies need to be turned off rather than default via the Clarity GUI, this line of code then enables
		// them, if and only id consent has been given.
		window.clarity('consent'); // https://learn.microsoft.com/en-us/clarity/setup-and-installation/cookie-consent
		this.hideBanner();
	};

	handleDecline = () => {
		// Taken from the sample code see: https://www.youtube.com/watch?v=MqAEbshMv84&t=116s
		// analytics and marketing are used by the Google Tag Manager code, preferences added
		// by me for EULA acceptance to be 'remembered'
		this.setConsent({
			analytics: false,
			marketing: false,
			preferences: false,
		});
		this.hideBanner();
	};

	hideBanner = () => {
		document.getElementById('CookieBanner').style.display = 'none';
	};

	setConsent = (consent) => {
		// Taken from the sample code see: https://www.youtube.com/watch?v=MqAEbshMv84&t=116s
		// analytics and marketing are used by the Google Tag Manager code, preferences added
		// by me for EULA acceptance to be 'remembered'
		const consentMode = {
			'ad_storage': consent.marketing ? 'granted' : 'denied',
			'analytics_storage': consent.analytics ? 'granted' : 'denied',
			'personalisation': consent.preferences ? 'granted' : 'denied',
		};
		this.gtag(consent);
		localStorage.setItem('consentMode', JSON.stringify(consentMode));
	};

	render() {
		return (
			<div id={`CookieBanner`}>
				<p>This website uses cookies to improve your experience and our marketing.
					By clicking 'accept', we'll assume you're ok with this, but you can opt out if you wish
					by clicking 'decline'. To find out more about how we use cookies, please see&nbsp;
				<a href='https://optibrium.com/cookie-policy/' target='_blank' rel="noreferrer">our cookie policy</a>.</p>
				<div className='button-group'>
					<button
						alt='accept'
						id="buttonAcceptAll"
						onClick={this.handleAccept}
						title='accept'
					>Accept</button>
					<button
						alt='decline'
						id="butttonRejectAll"
						onClick={this.handleDecline}
						title='decline'
					>Decline</button>
				</div>
			</div>
		);
	};
}