import './Login.scss';
import {
	Footer,
	PasswordFieldWithEye,
} from '..';
import optibriumLight from '../../images/optibrium_logo_light_text.svg';
import React from 'react';
export class PasswordInitialise extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			collapse: false,
			password: '',
			valid: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.validate = this.validate.bind(this);
	}

	handleChange = (field, value) => {
		switch (field) {
			default: {
				this.setState({ [field]: value }, this.validate);
				break;
			}
		}
	};

	render() {
		const {
			app,
			animated,
			email,
			handlePasswordInitialise,
			passwordError = '',
		} = this.props;

		const {
			password = '',
			passwordCopy = '',
			valid,
		} = this.state;

		return (
			<div id={`login`} className={`${app === 'adminPanel' ? 'file-manager' : ''}`}>
				<a
					id='optibrium'
					href='https://optibrium.com'
				>
					<img
						alt='Optibrium'
						src={optibriumLight}
					/>
				</a>
				<div id='container' className={`${animated ? "animated" : "static"}}`}>
					<form
						autoComplete='off'
						id="passwordSet"
						onSubmit={handlePasswordInitialise}
					>
						<div className='narrow'>
							<div
								alt='StarDrop'
								id='welcome'
								title='StarDrop'
							>
								{/* <span className='welcome-to'>Welcome to</span> */}
								<span className='password-set'>Update password</span>
							</div>
							<input
								autoComplete='new-email' // Pretty much impossible: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
								className={`text-field ${passwordError === '' ? '' : 'error'}`}
								id="email"
								onChange={(e) => this.handleChange('email', e.target.value)}
								placeholder="Email"
								required
								style={{ 'display': 'none' }}
								tabIndex={1}
								type="email"
								value={email}
							/>
							<PasswordFieldWithEye
								id={`password`}
								handleChange={this.handleChange}
								placeholder={`New password`}
								style={{ 'marginTop': '12px' }}
								tabIndex={2}
								value={password}
							/>
							<div
								className={`error password-error ${passwordError === '' ? '' : 'show'}`}
								id='error'
							>{passwordError}</div>
							<div
								className={`error password-error ${passwordError === '' ? 'show' : ''}`}
								id='errorPlaceholder'>&nbsp;</div>
							<PasswordFieldWithEye
								id={`passwordCopy`}
								handleChange={this.handleChange}
								placeholder="Confirm password"
								tabIndex={3}
								value={passwordCopy}
							/>
							<div
								className={`error show`}
								id='placeholder'>&nbsp;</div>
							<button
								className={`${!valid ? 'disabled' : ''}`}
								disabled={!valid}
								id="submit"
								tabIndex={4}
								type='submit'
							>Update password</button>
						</div>
					</form>
					<Footer
						app={app}
					/>
				</div>
			</div>

		);
	};

	validate = () => {
		const {
			password,
			passwordCopy
		} = this.state;
		// console.log(`valid email: ${email} password: ${password} eulaAccepted: ${eulaAccepted}`);
		if (password && passwordCopy) {
			if (password !== passwordCopy) {
				this.setState({
					passwordError: 'Passwords must match',
					valid: false,
				});
			} else {
				this.setState({
					passwordError: '',
					valid: password && passwordCopy !== '' && password === passwordCopy,
				});
			}
		}
	};
}