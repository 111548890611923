import './Footer.scss';
import React from 'react';

export class Footer extends React.Component {
	render() {
		const {
			adminPanel,
			appName
		} = this.props;
		let starDropURL = document.location.href;
		let linkText = `StarDrop`;

		if (adminPanel) {
			starDropURL = starDropURL.replace('admin.', 'login.');
		}else{
			starDropURL = starDropURL.replace('login.', 'admin.');
			// starDropURL = `${document.location.protocol}//admin.${document.location.host}`;
			linkText = `Admin`;
		}
		return (
			<footer>
				<ul>
					<li><a href='https://optibrium.com/' target='_blank' rel="noreferrer">Home</a></li>
					{/* <li><a href='https://optibrium.com/contact/'>Contact</a></li> */}
					{/* <li><a href='https://www.optibrium.com'>Help</a></li> */}
					{/* <li><a href='https://optibrium.com/privacy-policy/'>Cookie</a></li>
					<li><a href='https://optibrium.com/privacy-policy/'>Preferences</a></li> */}
					{/* <li><a href='https://optibrium.com/privacy-policy/' target='_blank' rel="noreferrer">Privacy&nbsp;Policy</a></li>
					<li><a href='https://optibrium.com/cookie-policy/' target='_blank' rel="noreferrer">Cookie&nbsp;Policy</a></li> */}
					<li><a href='https://optibrium.com/privacy-policy/' target='_blank' rel="noreferrer">Privacy&nbsp;&amp;&nbsp;cookies&nbsp;policies</a></li>
					{/* <li><a href='https://optibrium.com/community/' target='_blank' rel="noreferrer">Community</a></li> */}
					<li><a href='https://optibrium.com/resources/' target='_blank' rel="noreferrer">Customer Hub</a></li>
					{/* <li><a href='https://optibrium.com/stardrop-help/' target='_blank' rel="noreferrer">Help</a></li> */}
					<li><a href='https://optibrium.com/products/support/' target='_blank' rel="noreferrer">Support</a></li>
					{/* <li><a href='https://www.optibrium.com'>Terms of use</a></li> */}
					{appName === 'StarDrop' ?
						<li><a href={`${starDropURL}`} target='_blank' rel="noreferrer">{linkText}</a></li>
						:
						null
					}
				</ul>
			</footer>
		);
	};
}