import React from 'react';

export class PasswordFieldWithEye extends React.Component {

	constructor(props) {
		super(props);
		this.state = ({
			revealPassword: false,
		});

		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.toggleRevealPassword = this.toggleRevealPassword.bind(this);

		this.passwordRef = React.createRef();
	}

	handleKeyPress = (e) => {
		// console.log("handleKeyPress", e);
		if (e.altKey && e.keyCode === 119) this.toggleRevealPassword(e); // alt f8
	};

	toggleRevealPassword = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const inputPassword = this.passwordRef.current;
		if (inputPassword.type === "password") {
			inputPassword.type = "text";
			this.setState({revealPassword: true});
		} else {
			inputPassword.type = "password";
			this.setState({revealPassword: false});
		}
	};

	render = () => {
		const {
			className,
			id,
			handleChange,
			loginError,
			placeholder,
			style,
			tabIndex,
			value,
		} = this.props;

		const { revealPassword = false } = this.state;

		return (
			<div
				// className={`text-field ${className} ${loginError === '' ? '' : 'error'}`}
				style={{ 'position': 'relative', ...style }}
			>
				<input
					autoComplete='new-password' // Pretty much impossible: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
					className={`text-field password ${className} ${loginError && loginError !== '' ? 'error' : ''}`}
					id={id}
					onChange={(e) => handleChange(id, e.target.value)}
					onKeyDown={this.handleKeyPress}
					pattern=".*"
					placeholder={placeholder}
					ref={this.passwordRef}
					required
					tabIndex={tabIndex}
					type="password"
					value={value}
				/>
				<span
					className={`reveal-password ${revealPassword ? 'reveal' : ''} ${value.length === 0 ? 'hide' : ''}`}
					onClick={this.toggleRevealPassword}
					onKeyDown={this.handleKeyPress}
					title={`${revealPassword ? 'Hide' : 'Reveal'} password`}
				>{`${revealPassword ? 'Hide' : 'Reveal'} password`}</span>
			</div>
		);
	};
}